

<script lang="ts">
  import {
    modePerso,
    niveauxObjectifs,
    niveauxSequences
  } from '../../services/store'
  import type { ObjectifExercice, Doc, SequenceSequence, SequenceActivite } from '../../services/types'
  import { getTitre, supprimerGraines } from '../../services/outils'
  import { goVue } from '../../services/navigation'
  import { afterUpdate, onDestroy, tick } from 'svelte'
  import {writable} from 'svelte/store'
  import type { Unsubscriber } from 'svelte/store'
  import BoutonsExercices from '../shared/BoutonsExercices.svelte'
  import DownloadLine from '../shared/DownloadLine.svelte'
  import { mathaleaRenderDiv } from '../../../lib/mathalea';

  let niveau = ''
  export let referenceSequence = ''
  let sequence: SequenceSequence = {
    reference: '',
    referenceDonnee: '',
    titre: '',
    niveau: '',
    numero: 0,
    periode: 0,
    objectifs: [],
    prerequis: [],
    calculsMentaux: [],
    questionsFlash: [],
    lienQuestionsFlash: '',
    slugEvalBrevet: '',
    lienEval: '',
    lienPrerequis: '',
    lienEvalBrevet: '',
    documents: [],
    telechargementsDisponibles: { cours: false, resume: false, mission: false, fiche: false }
  }
  let exercicesSequence: ObjectifExercice[] = []
  let nomsExercicesSequence: string[] = []
  let niveauxObjectifsUnsubscribe: Unsubscriber
  let niveauxSequencesUnsubscribe: Unsubscriber
  let lienEvalModifie = ''
  let lienPrerequisModifie = ''
  let fieldValue =generateRandomSeed() 
  let linkEvalUpdated = updateLinkWithRandomSeed(lienEvalModifie, fieldValue);
  let linkPrerequisUpdated = updateLinkWithRandomSeed(lienPrerequisModifie, fieldValue);

  export let title = 'gemaths.net - Séquence'
  trouverSequence()
  surveillerLeChargementDesDonnees()
  afterUpdate(async () => {
    if (sequence.lienEval !== ''){
      await tick()
      lienEvalModifie = supprimerGraines(sequence.lienEval)
      if (sequence.lienPrerequis !== ''){
      lienPrerequisModifie = supprimerGraines(sequence.lienPrerequis)}
      else{
        lienPrerequisModifie = ''
      }
      linkEvalUpdated = updateLinkWithRandomSeed(lienEvalModifie, fieldValue);
      linkPrerequisUpdated = updateLinkWithRandomSeed(lienPrerequisModifie, fieldValue)
      console.log(linkEvalUpdated)
    }
  })



// Function to handle changes in the input field
  // Function to handle changes in the input field
  function handleInputChange(event) {
    fieldValue = event.target.value; // Update fieldValue when input changes
    linkEvalUpdated = updateLinkWithRandomSeed(lienEvalModifie, fieldValue); // Update linkUpdated
    linkPrerequisUpdated = updateLinkWithRandomSeed(lienPrerequisModifie, fieldValue)
  }

 

function updateLinkWithRandomSeed(link, randomSeed) {
  if (link === '') {
    return '';
  }
  else{
  let modifiedLink = link;
  const regex = /uuid=[^&]+/g;
  const matches = modifiedLink.match(regex);
  const uniqueMatches = matches ? [...new Set(matches)] : [];

  if (!uniqueMatches) {
    return modifiedLink;
  }
  console.log(uniqueMatches)
  //if(uniqueMatches.length !== 1) {
   // uniqueMatches.shift(); // Remove the first element
    //  }
      uniqueMatches.forEach(uniqueMatches => {
    const newParam = '&alea=' + randomSeed + '&' + uniqueMatches;
    modifiedLink = modifiedLink.replaceAll(uniqueMatches, newParam);
  });
  modifiedLink = modifiedLink + '&alea=' + randomSeed;
  // Regular expression to match substrings starting with "alea=" or "&alea=" followed by any characters until the next "&" or end of the string
  console.log(modifiedLink)
  const regex1 = /(?:alea\/\?&alea=[^&]*&?|alea\/\?&alea=[^&]*&?)/g;
  // Replace matched substrings with an empty string
  modifiedLink = modifiedLink.replaceAll(regex1, 'alea/?');
  return modifiedLink;}
}

    function generateRandomSeed() {
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    }

  function surveillerLeChargementDesDonnees () {
    niveauxObjectifsUnsubscribe = niveauxObjectifs.subscribe(() =>
      trouverSequence()
    )
    onDestroy(niveauxObjectifsUnsubscribe)
    niveauxSequencesUnsubscribe = niveauxSequences.subscribe(() =>
      trouverSequence()
    )
    onDestroy(niveauxSequencesUnsubscribe)
  }

  function lesDonneesSontChargees () {
    return $niveauxObjectifs.length > 0 && $niveauxSequences.length > 0
  }

  function trouverSequence () {
    if (lesDonneesSontChargees() && referenceSequence.slice(0, 1) === 'S') {
      $niveauxSequences.find((niveauSequence) => {
        return niveauSequence.sequences.find((sequenceTrouve) => {
          if (sequenceTrouve.reference === referenceSequence) {
            niveau = niveauSequence.nom
            sequence = sequenceTrouve
            title = sequence.titre
          }
          return sequenceTrouve.reference === referenceSequence
        })
      })
      listerExercices()
    }
  }

  function listerExercices () {
    exercicesSequence = []
    nomsExercicesSequence = []
    for (const objectif of sequence.objectifs) {
      for (const exercice of objectif.exercices) {
        exercicesSequence.push(exercice)
        nomsExercicesSequence.push(objectif.reference + ' ' + getTitre(objectif))
      }
    }
  }
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>

<h1 id="titre" class="title text-2xl md:text-4xl font-semibold p-1 is-{niveau}">
  <!-- {sequence.reference.slice(3)} -->
<br />{sequence.titre}
</h1>
<div class="is-{niveau}">
  <h2 class="subtitle text-xl md:text-3xl p-3 is-{niveau}">Objectifs et automatismes</h2>
  <ul class="p-3">  
    Objectifs :
    {#each sequence.objectifs as objectif}
      {#if objectif.reference.slice(1, 2) !== 'X'}
        <li class=" p-1 md:p-2 is-{niveau}">
          <a
            href="/?v=objectif&ref={objectif.reference}"
            on:click={(event) => goVue(event, 'objectif', objectif.reference)}
          >
            {objectif.reference} : {getTitre(objectif)}
          </a>
        </li>
      {/if}
    {/each}
    {#if sequence.prerequis[0] !== undefined && sequence.prerequis[0].reference !== ''}
        <p class="text-sm md:text-base">
          Automatismes :
        </p>
        <ul>
          {#each sequence.prerequis as prereq}
          {#if prereq.reference.slice(1, 2) !== 'X'}
          <div class="container">
            <ul class="container-list">
            <li class=" text-sm md:p-2 is-{niveau}">
              <a
                href="/?v=objectif&ref={prereq.reference}"
                on:click={(event) => goVue(event, 'objectif', prereq.reference)}
              >
                {prereq.reference} : {getTitre(prereq)}
              </a>
            </li>
            </ul>
          </div>
          {/if}
        {/each}
        </ul>
    {/if}
      </ul>
</div>
<!-- {#if sequence.activiteSup.length > 0}
    <div
      id="divExercices"
      class="is-{niveau}"
    >
      <h2 class="subtitle text-xl md:text-3xl p-3 is-{niveau}">
        Exercices supplémentaires vus en cours
      </h2>
      <ul class="p-6 ">
        {#each sequence.activiteSup as exercice, i}
          <li class="p-1 md:p-2">
            <BoutonsExercices
              lienExercices = {exercice.lien}
              titre = {exercice.description}
            />
          </li>
        {/each}
      </ul>
    </div>
  {/if} -->
<div
  id="divEvaluation"
  class="is-{niveau} {sequence.organisationCours ||sequence.telechargementsDisponibles.cours ||
  sequence.telechargementsDisponibles.resume ||
  sequence.telechargementsDisponibles.mission
    ? ''
    : ' is-fin'}"
>
  <h2 id="Evaluation" class="subtitle text-xl md:text-3xl p-3 is-{niveau}">S'entraîner</h2>
  <div style="display: inline-block; border: 0px solid #ccc; border-radius: 5px; padding: 5px;">
    <label for="randomSeedInput">Graine aléatoire:</label>
    <input type="text" bind:value={fieldValue} on:input={handleInputChange} id="randomSeedInput" maxlength="5" pattern="[a-zA-Z0-9]{5}" title="Enter 5 random letters and digits" placeholder="Random Seed (5 characters)" style="width: 60px; height: 20px; padding: 5px;">
<p class="text-sm"> À partager pour travailler à plusieurs.</p>
</div>

      <div class="p  flex flex-col">
    {#if linkPrerequisUpdated !== ''}
    <div class="p-1 md:p-2">
      <BoutonsExercices
      exercices = {exercicesSequence}
        lienExercices = {linkPrerequisUpdated}
        titre = {'Entraîner les automatismes'}
        reference = {sequence.reference}
        nomsPanier = {nomsExercicesSequence}
      />
    </div>
    {/if}
    <div class="p-1 md:p-2">
      <BoutonsExercices
        exercices = {exercicesSequence}
        lienExercices = {linkEvalUpdated}
        titre = {'Entraîner les objectifs du cours' + (niveau === '3e' ? ' (Automatismes)' : '')}
        reference = {sequence.reference}
        nomsPanier = {nomsExercicesSequence}
      />
    </div>
    {#if niveau === '3e' && sequence.lienEvalBrevet !== '' && sequence.lienEvalBrevet !== undefined}
      <div class="p-1 md:p-2">
        <BoutonsExercices
          exercices = {[]}
          reference = {sequence.reference + ' Brevet '}
          nomsPanier = {[sequence.reference]}
          lienExercices = {sequence.lienEvalBrevet}
          titre = {'S\'entraîner pour l\'évaluation (Exercices de brevet)'}
        />
      </div>
    {/if}
</div>
{#if sequence.documents.length !== 0}
<h2 class="subtitle text-xl md:text-3xl p-3 is-{sequence.niveau}">Documents</h2>
{#each sequence.documents as document, i}
<div>
  <a href="/gemaths/pdf/{sequence.niveau}/{sequence.reference.split('-')[1]}/{document.lien}" download> {document.titre} </a>
</div>
{/each}
{/if}
</div>