<script lang="ts">
  import { onDestroy } from 'svelte'
  import { reference } from '../services/store'
  import Chapitre from './chapitres/ChapitreNormal.svelte'

  let referenceChapitre = ''
  const referenceChapitreUnsubscribe = reference.subscribe((reference) => { referenceChapitre = reference })
  onDestroy(referenceChapitreUnsubscribe)

</script>

<div class="w-screen max-w-screen-lg">
    <Chapitre referenceChapitre={referenceChapitre} />
</div>
