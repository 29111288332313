<script lang="ts">
  import TabsMenuLink from './TabsMenuLink.svelte'

  export let isMd: boolean
  export let isBasketAvailable: boolean
  export let vue: string
  export let onHeadTabsMenuClicked: (event: MouseEvent, vue: string) => void

</script>
<div class="overflow-scrolling-touch flex overflow-hidden overflow-x-auto">
  <ul class="flex flex-grow justify-center tabs-menu hover:text-white">
    <TabsMenuLink
      destinationVue={'accueil'}
      activeCondition={vue === 'accueil' || vue === ''}
      color={'warning'}
      imgSrc={'gemaths/img/cc0/homepage-svgrepo-com.svg'}
      imgAlt={'Maison'}
      {isMd}
      {onHeadTabsMenuClicked}
    />
    <TabsMenuLink
      destinationVue={'sequences'}
      activeCondition={vue === 'sequences' || vue === 'sequence'}
      color={'fuchsia'}
      imgSrc={'gemaths/img/cc0/study-notes-svgrepo-com.svg'}
      imgAlt={'Carnet de notes'}
      {isMd}
      {onHeadTabsMenuClicked}
    />
    <TabsMenuLink
      destinationVue={'objectifs'}
      activeCondition={vue === 'objectifs' || vue === 'objectif'}
      color={'success'}
      imgSrc={'gemaths/img/cc0/target-svgrepo-com.svg'}
      imgAlt={'Cible avec une flèche au centre'}
      {isMd}
      {onHeadTabsMenuClicked}
    />
<!-- <TabsMenuLink
    destinationVue={'chapitres'}
    activeCondition={vue === 'chapitres' || vue === 'chapitre'}
    color={'link'}
    imgSrc={'gemaths/img/cc0/stacked-book-svgrepo-com.svg'}
    imgAlt={'Chapitres pour étudier'}
    {isMd}
    {onHeadTabsMenuClicked}
  /> -->
    <!-- <TabsMenuLink
      destinationVue={'revisions'}
      activeCondition={vue === 'revisions'}
      color={'sponsor'}
      imgSrc={'gemaths/img/gvalmont/automatismes-regular.svg'}
      imgAlt={'Tête avec un engrenage à l\'intérieur'}
      {isMd}
      {onHeadTabsMenuClicked}
    />
    <TabsMenuLink
      destinationVue={'eleves'}
      activeCondition={vue === 'eleves' || vue === 'lexique' || vue === 'tutos' || vue === 'telechargements'}
      color={'purple'}
      imgSrc={'gemaths/img/cc0/backpack-svgrepo-com.svg'}
      imgAlt={'Sac à dos d\'élève'}
      {isMd}
      {onHeadTabsMenuClicked}
    />
    <TabsMenuLink
      destinationVue={'outils'}
      activeCondition={vue === 'outils' || vue === 'mathador' || vue === 'progressions'}
      color={'green'}
      imgSrc={'gemaths/img/cc0/classroom-svgrepo-com.svg'}
      imgAlt={'Enseignant qui montre un tableau à une classe'}
      {isMd}
      {onHeadTabsMenuClicked}
    />
    <TabsMenuLink
      destinationVue={'panier'}
      displayCondition={isBasketAvailable}
      activeCondition={vue === 'panier'}
      color={'fuchsia'}
      imgSrc={'gemaths/img/cc0/cart-content-svgrepo-com.svg'}
      imgAlt={'Caddie'}
      {isMd}
      {onHeadTabsMenuClicked}
    /> -->
  </ul>
</div>
