<h1 class="title is-1 has-text-centered pt-14">POLITIQUE DE CONFIDENTIALITÉ</h1>
<h1 class="subtitle is-2 pt-6 has-text-centered">
  MISE À DISPOSITION DU SITE WEB ET CRÉATION DE FICHIERS JOURNAUX
</h1>
<p class="has-text-justified">
  Afin de respecter ses obligations légales, l'hébergeur du site web (Infomaniak) peut
  collecter et conserver des données techniques relatives à l’utilisation des
  services (IP, User Agent) pour une durée d'un an.
</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">UTILISATION DES COOKIES</h1>
<p class="has-text-justified">gemaths n'utilise aucun cookie.</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">STOCKAGE DES DONNÉES</h1>
<p class="has-text-justified">
  gemaths ne récupère aucune donnée de ses utilisateurs.<br />
  Certaines données sont stockées localement dans le navigateur mais ne sont à
  aucun moment envoyées sur internet.
</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">
  UTILISATION DE SERVICES TIERS
</h1>
<p class="has-text-justified">
  gemaths affiche des éléments de services tiers qui peuvent éventuellement
  récupérer des données personnelles.
</p>
<br />
<ul>
  <li>
    Le calcul mental peut venir de <a href="https://mathsmentales.net/cgu.html"
      >MathsMentales</a
    >
  </li>
  <li>
    Les vidéos viennent de <a
      href="https://policies.google.com/privacy?hl=fr#infocollect">YouTube</a
    >
    avant d'être filtrées par
    <a href="https://ladigitale.dev/confidentialite.html">DigiPlay</a>
  </li>
  <li>
    Certaines activités peuvent provenir de :
    <ul>
      <li>
        - <a href="https://mathix.org/linux/politique-de-confidentialite-2"
          >mathix</a
        >
      </li>
      <li>
        - <a href="https://www.sesamath.net/index.php?page=mentions_legales"
          >Sesamath</a
        >
      </li>
      <li>- <a href="https://www.geogebra.org/privacy">Geogebra</a></li>
      <li>- <a href="https://www.clicmaclasse.fr/">clicmaclasse</a></li>
    </ul>
  </li>
</ul>
