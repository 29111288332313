import type { ChapitreNiveau, ObjectifNiveau, SequenceNiveau, SequenceSequenceParticuliere, CalendrierAnnee, LexiqueItem } from './types'
import { niveauxObjectifs as storeNiveauxObjectifs, niveauxSequences as storeNiveauxSequences, niveauxChapitres as storeNiveauxChapitres, sequencesParticulieres as storeSequencesParticulieres, calendrierAnneeEnCours as storeCalendrierAnneeEnCours, lexique as lexiqueStore } from './store'
import sequencesModifieesJson from '../../gemaths/json/sequences_modifiees.json'
import chapitresJson from '../../gemaths/json/chapitres_modifies.json'
import objectifsModifiesJson from '../../gemaths/json/objectifs_modifies.json'
import lexiqueModifieJson from '../../gemaths/json/lexique_modifie.json'
import sequencesParticulieresJson from '../../gemaths/json/sequencesParticulieres.json'
import calendrierJson from '../../gemaths/json/calendrier.json'

let niveauxObjectifs = [] as ObjectifNiveau[]
let niveauxSequences = [] as SequenceNiveau[]
let niveauxChapitres = [] as ChapitreNiveau[]
let sequencesParticulieres = [] as SequenceSequenceParticuliere[]
let lexique = [] as LexiqueItem[]
miseEnCacheDesDonnees()

function miseEnCacheDesDonnees () {
  miseEnCacheNiveauxEtSequences()
  miseEnCacheSequencesParticulieres()
  miseEnCacheLexique()
  miseEnCacheCalendrier()
}

function miseEnCacheNiveauxEtSequences () {
  niveauxSequences = sequencesModifieesJson as SequenceNiveau[]
  storeNiveauxSequences.set(niveauxSequences)
  niveauxObjectifs = objectifsModifiesJson as ObjectifNiveau[]
  storeNiveauxObjectifs.set(niveauxObjectifs)
  niveauxChapitres = chapitresJson as ChapitreNiveau[]
  storeNiveauxChapitres.set(niveauxChapitres)
}

function miseEnCacheSequencesParticulieres () {
  sequencesParticulieres = sequencesParticulieresJson as SequenceSequenceParticuliere[]
  storeSequencesParticulieres.set(sequencesParticulieres)
}

function miseEnCacheLexique () {
  lexique = lexiqueModifieJson as LexiqueItem[]
  lexiqueStore.set(lexique)
}

function miseEnCacheCalendrier () {
  const calendrierAnnees = calendrierJson as CalendrierAnnee[]
  const annee = new Date().getFullYear()
  const jourNumero = getDayOfYear()
  let periodeNumero: number
  let typeDePeriode: 'cours' | 'vacances'
  let semaineDansLaPeriode: number
  let trouve = false
  for (const annee of calendrierAnnees) {
    for (const periode of annee.periodes) {
      if (jourNumero >= periode.debut && jourNumero <= periode.fin) {
        periodeNumero = periode.numero
        typeDePeriode = periode.type
        semaineDansLaPeriode = Math.floor((jourNumero - periode.debut) / 7) + 1
        trouve = true
      }
      if (trouve) break
    }
    if (trouve) break
  }
  storeCalendrierAnneeEnCours.set({ annee, jourNumero, periodeNumero, semaineDansLaPeriode, typeDePeriode })
}

function getDayOfYear () {
  const now = new Date()
  const begin = new Date(now.getFullYear(), 0, 0)
  const diff = (now.getTime() - begin.getTime()) + ((begin.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000)
  const oneDay = 1000 * 60 * 60 * 24
  return Math.floor(diff / oneDay)
}
