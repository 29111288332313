<script lang="ts">
  import { niveauxSequences, niveauxChapitres } from '../../services/store';
  import { afterUpdate, onDestroy, tick } from 'svelte';
  import type { Unsubscriber } from 'svelte/store';
  import type { Chapitre } from '../../services/types';
    import { mathaleaRenderDiv } from '../../../lib/mathalea';
    import Sequence from '../Sequence.svelte';
    import { goVue } from '../../services/navigation';

  export let referenceChapitre = '';
  let chapitre: Chapitre | undefined;
  let niveauxSequencesUnsubscribe: Unsubscriber;

  export let title = 'gemaths.net - Séquence';

  surveillerLeChargementDesDonnees();
  afterUpdate(async () => {
    if (chapitre.infos !== '') {
      await tick()
      const infosChapitre = document.getElementById('infosChapitre')
      if (infosChapitre !== null) mathaleaRenderDiv(infosChapitre,1.3)
    }
  })


  function surveillerLeChargementDesDonnees() {
    niveauxSequencesUnsubscribe = niveauxSequences.subscribe(() => {
      trouverChapitre();
    });
    onDestroy(() => {
      niveauxSequencesUnsubscribe();
    });
  }

  function lesDonneesSontChargees() {
    // Ensure these stores are already populated before finding the sequence
    return $niveauxSequences.length > 0 && $niveauxChapitres.length > 0;
  }

  function trouverChapitre() {
    if (lesDonneesSontChargees()) {
      $niveauxChapitres.find((niveauChapitres) => {
      niveauChapitres.chapitres.forEach(chap => {
          if (chap.reference === referenceChapitre) {
            chapitre = chap
          }
        })
    })
  }
}

  // function trouverSequence(ref) {
  //   if (lesDonneesSontChargees()) {
  //     $niveauxSequences.forEach(sequence => {
  //         if (sequence.reference === ref) {
  //           return sequence
  //         }
  //       })
  //   }
  // }

  // Initial call to ensure `chapitre` is set if data is already loaded
  trouverChapitre();
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>


<h1 id="titre" class="title text-2xl md:text-4xl font-semibold p-4 is-{chapitre.niveau}">
  {chapitre.titre}
</h1>
<div class="is-{chapitre.niveau} {chapitre.sequencesListe.length && chapitre.documents.length
  ? 0
  : ' is-fin'}">
  <h2 class="subtitle text-xl md:text-3xl p-3 is-{chapitre.niveau}">Informations générales</h2>
  <div class="p-3" style="text-align: justify" >
    {#if chapitre.infos !== ''}
      <p
        id="infosChapitre"
        contenteditable="false"
        bind:innerHTML={chapitre.infos}
      />
    {/if}
  </div>

  {#if chapitre.sequencesListe.length !== 0}
  <div class="is-{chapitre.niveau} {chapitre.documents.length
  ? 0
  : ' is-fin'}">
  <h2 class="subtitle text-xl md:text-3xl p-3 is-{chapitre.niveau}">Séquences</h2>
  {#each chapitre.sequencesListe as sequence, i}
  <a
  href="/?v=sequence&ref={sequence.reference}"
  on:click={(event) =>
    goVue(event, 'sequence', sequence.reference)}>
  <div>
    Séquence {i+1} : {sequence.titre}
  </div>
</a>
{/each}
</div>
  {/if}
  {#if chapitre.documents.length !== 0}
  <h2 class="subtitle text-xl md:text-3xl p-3 is-{chapitre.niveau}">Documents</h2>
  {#each chapitre.documents as document, i}
  <div>
    <a href="/gemaths/pdf/{chapitre.niveau}/{chapitre.reference.split('-')[1]}/{document.lien}" download> {document.titre} </a>
  </div>
{/each}
{/if}
</div>
