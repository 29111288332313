<script lang="ts">
  import { goVue } from '../services/navigation'

</script>
<style>
  /* Increasing line height for better readability */
/* Adding some padding to the sections */
.section {
  border: 2px solid #ccc; /* Adjust border color and thickness as needed */
  border-radius: 5px; /* Optional: rounded corners */
  padding: 1rem;
  margin-bottom: 1rem; /* Optional: add some space between sections */
}


/* Changing link color and removing underline */
a {
  color: #0366d6; /* blue color */
  text-decoration: none;
}

/* Adding some margin to the list items for better spacing */
ul > li {
  margin-bottom: 0.5rem;
}

/* Changing list style to disc */
ul {
  list-style: disc;
}
ul > li {
    margin-left: 10px;
  }
  ul {
    list-style: circle;
  }
  ul * {
    text-align: left;
  }
</style>
<h1 class="title is-1 has-text-centered pt-14">MENTIONS LÉGALES</h1>
<h1 class="subtitle is-2 pt-6 has-text-centered">IDENTITÉ</h1>
<p class="has-text-justified">
  <b>Nom du site Web :</b> gemaths<br />
  <b>Adresse :</b> https://gemaths.net<br />
  <b>Propriétaire et responsable de publication  :</b> Nathan Scheinmann<br />
  <b>Hébergement :</b> Infomaniak Network SA, Rue Eugène Marziano 25, 1227 Les Acacias, Genève, Suisse <br />
</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">INFORMATIONS</h1>
<p class="has-text-justified">
  Les informations et documents du site sont présentés à titre indicatif,
  n’ont pas de caractère exhaustif et ne peuvent engager la responsabilité du
  propriétaire du site.
  <br /><br />
  Le propriétaire du site ne peut être tenu responsable des dommages directs et
  indirects consécutifs à l’accès au site.
</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">PROPRIÉTÉ INTELLECTUELLE</h1>
<p class="has-text-justified">
  Le contenu du site est sous licence <a
    href="https://creativecommons.org/licenses/by-sa/4.0/deed.fr"
    target="_blank"
    rel="noopener noreferrer">CC-BY-SA 4.0</a
  >.<br />
  Le
  <a
    href="https://forge.aeif.fr/gvalmont/topmathalea"
    target="_blank"
    rel="noopener noreferrer"
  >
    code source du site</a
  >
  est sous licence
  <a
    href="https://www.gnu.org/licenses/agpl-3.0.html"
    target="_blank"
    rel="noopener noreferrer">AGPL 3.0</a
  >.
</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">LIENS</h1>
<p class="has-text-justified">
  Le propriétaire du site décline toute responsabilité et n’est pas engagé par
  le référencement via des liens hypertextes, de ressources tierces présentes
  sur Internet, tant en ce qui concerne leur contenu que leur pertinence.
  <br /><br />
  Le propriétaire du site autorise les liens hypertextes vers l’une des pages de
  ce site, à condition que ceux-ci ouvrent une nouvelle fenêtre et soient présentés
  de manière non équivoque afin d’éviter tout risque de confusion entre le site
  citant et le propriétaire du site ainsi que toute présentation tendancieuse,
  ou contraire aux lois en vigueur.
  <br /><br />
  Le propriétaire du site se réserve le droit de demander la suppression d’un lien
  s’il estime que le site source ne respecte pas les règles ainsi définies.
</p>
<h1 class="subtitle is-2 pt-6 has-text-centered">CONFIDENTIALITÉ</h1>
<p class="has-text-justified">
  Le site ne recueille pas d’informations personnelles.
  <br />
  Voir également la page
  <button
    class="has-text-link"
    on:click={(event) => goVue(event, 'politique-de-confidentialite')}
    >Politique de confidentialité</button
  >.
</p>
