<script lang="ts">
  import { onMount } from 'svelte'
  import GrosBouton from './shared/GrosBouton.svelte'

  onMount(() => {
    window.scrollTo({
      top: 0
    })
  })
</script>

<svelte:head>
  <title>gemaths.net - Objectifs maths Genève !</title>
</svelte:head>

<GrosBouton
  vue='sequences'
  couleur='fuchsia'
  texte='Semaines'
  lienImage='gemaths/img/cc0/study-notes-svgrepo-com.svg'
  altImage='Carnet de notes'
/>
<GrosBouton
  vue='objectifs'
  couleur='success'
  texte='Objectifs'
  lienImage='gemaths/img/cc0/target-svgrepo-com.svg'
  altImage='Cible avec une flèche au centre'
/>
<!-- <GrosBouton
  vue='chapitres'
  couleur='link'
  texte='Chapitres'
  lienImage='gemaths/img/cc0/stacked-book-svgrepo-com.svg'
  altImage='Chapitres pour étudier'
  /> -->
<!-- <GrosBouton
  vue='revisions'
  couleur='sponsor'
  texte='Révisions'
  lienImage='gemaths/img/gvalmont/automatismes-regular.svg'
  altImage="Tête avec un engrenage à l'intérieur"
/>
<GrosBouton
  vue='eleves'
  couleur='purple'
  texte='Outils pour<br>les élèves'
  lienImage='gemaths/img/cc0/backpack-svgrepo-com.svg'
  altImage="Sac à dos d'élève"
/>
<GrosBouton
  vue='outils'
  couleur='green'
  texte='Outils pour<br>la classe'
  lienImage='gemaths/img/cc0/classroom-svgrepo-com.svg'
  altImage="Enseignant montrant un tableau face à une classe"
/> -->