<style>
  /* Increasing line height for better readability */
/* Adding some padding to the sections */
.section {
  border: 2px solid #ccc; /* Adjust border color and thickness as needed */
  border-radius: 5px; /* Optional: rounded corners */
  padding: 1rem;
  margin-bottom: 1rem; /* Optional: add some space between sections */
}


/* Changing link color and removing underline */
a {
  color: #0366d6; /* blue color */
  text-decoration: none;
}

/* Adding some margin to the list items for better spacing */
ul > li {
  margin-bottom: 0.5rem;
}

/* Changing list style to disc */
ul {
  list-style: disc;
}
ul > li {
    margin-left: 10px;
  }
  ul {
    list-style: circle;
  }
  ul * {
    text-align: left;
  }
</style>
<h1 class="title">INFORMATIONS SUR LE SITE</h1>
<section class="section">
  <h2 class="title is-2">Codes Sources</h2>
  Tous les exercices sur ce site sont issus de <a href="https://coopmaths.fr/alea/">MathALÉA</a> dont voici les contributeurs (j'en fais partie depuis février 2024) <br> <a href="https://coopmaths.fr/www/about/">contributeurs de MathALÉA</a> <br>
  <ul>
    <li>Ce site est un fork du site de Guillaume Valmont : <a href="https://topmaths.fr/">topmaths.fr</a> dont le code est disponible librement sur la Forge (<a href="https://forge.apps.education.fr/valmontguillaume/topmaths">code source de gemaths</a>). J'ai librement adapté la mise en page, le contenu des objectifs et des séquences au programme genevois.</li>
    <li>Le site <a href="https://topmaths.fr/">topmaths.fr</a> et donc ce site sont des surcouches de <a href="https://coopmaths.fr/alea/">MathALÉA</a>, un générateur d'exercices de mathématiques, dont le code est disponible sur la <a href="https://forge.app.education.fr/coopmaths/mathalea">Forge des Communs Numériques Éducatifs</a>. </li>
    <li>Le code source de gemaths est disponible et peut être utilisé librement, il est stocké sur <a href="https://forge.apps.education.fr/nathan.scheinmann-ext/gemaths-adaptation-ch">ce dépôt</a>. </li>
  </ul>
</section>
<section class="section">
<h2 class="title is-2">Remerciements aux élèves qui m'ont permis d'améliorer le site</h2>
  <ul>
    <li>2023-2024: Sam S.</li>
  </ul>
  </section>
<section class="section">
  <h2 class="title is-2">Avoir votre propre version de gemaths</h2>
  En construction
</section>

